<style>
    .ant-layout-header {
        background: #14909b;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background: rgba(0, 0, 0, 0.2);
    }

    .ant-menu-dark,
    .ant-menu-dark .ant-menu-sub {
        background: #14909b !important;
    }

    .ant-menu-submenu-title,
    .ant-menu-submenu-title:focus,
    .ant-menu-submenu-title:hover {
        color: #fff;
    }
</style>
<template>
    <a-layout>
        <a-layout-header v-show="!is_fullscreen">
            <div style="float:left;line-height:60px;margin-right: 30px">
                <img src="../assets/logo.png" style="height: 32px;margin:0px 16px 0px 0"/>
                <span style="color: #fff;font-size: 18px;letter-spacing: 1px;color:#fff;font-weight: bold">{{title}}</span>
            </div>
            <a-menu mode="horizontal" theme="dark" forceSubMenuRender :default-selected-keys="[router_view_key]"
                    :selectedKeys="[router_view_key]"
                    :style="{lineHeight:'63px',backgroundColor:'#14909B',color:'#ffffff'}">
                <template v-for="item in menu_list">
                    <a-menu-item :key="item.link" @click="goto_link(item.link)">
                        <i :class="item.icon" style="font-size:18px"></i> <span>{{item.title}}</span>
                    </a-menu-item>
                </template>
                <a-sub-menu class="last-info" :style="{backgroundColor:'#14909B',float:'right'}">
                    <template slot="title">
                        <img src="../assets/user.png" style="height: 24px;margin:0px 16px 0px 0"/>
                        {{name}}
                    </template>
                    <a-menu-item @click="get_myinfo">
                        <i class="iconfont icon-mima"></i> 修改密码
                    </a-menu-item>
                    <a-menu-divider/>
                    <a-menu-item @click="logout">
                        <i class="el-icon-switch-button"></i> 退出登录
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-header>
        <a-layout-content>
            <router-view :key="router_view_key"/>
        </a-layout-content>
        <a-modal title="修改密码" :visible="show_myinfo_form" cancelText="取消" okText="确认" @ok="submit_myinfo"
                 @cancel="cancel_myinfo">
            <a-form-model :model="myinfo" :colon="false">
                <a-form-model-item prop="id" v-show="false">
                    <a-input v-model="myinfo.id"/>
                </a-form-model-item>
                <a-form-model-item prop="pwd" label="重置密码">
                    <a-input v-model="myinfo.pwd"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </a-layout>
</template>
<script>
    import request from "../plugins/axios";

    export default {
        name: "layout",
        components: {},

        data() {
            return {
                //获取菜单列表
                menu_list: [
                    /*{
                        link: "/admin/index",
                        icon: "iconfont icon-fenxi",
                        title: "监控中心",
                    },
                    {
                        link: "/admin/user",
                        icon: "el-icon-user-solid",
                        title: "患者管理",
                    },
                    {
                        link: "/admin/device",
                        icon: "el-icon-files",
                        title: "设备管理",
                    },
                    {
                        link: "/admin/report",
                        icon: "el-icon-s-data",
                        title: "报告管理",
                    },
                    {
                        link: "/admin/system",
                        icon: "el-icon-s-data",
                        title: "系统管理",
                    },*/
                ],
                title: '健康中国服务平台',
                name: window.localStorage.getItem("name"),
                head: ".",
                show_myinfo_form: false,
                myinfo: {},
                myinfo_rules: {},
                is_fullscreen: false,
            };
        },
        created() {
            let that = this
            let ua = JSON.parse(localStorage.getItem('ua'));
            let menu = ua.menu
            let menu_list = []
            menu.forEach(function (item, index) {
                let detail = {
                    link: item.url,
                    icon: item.icon,
                    title: item.name,
                }
                menu_list.push(detail)
            })
            that.menu_list = menu_list
            console.log(ua.hosptail_name)
            let hosptail_name = ua.hosptail_name
            if (hosptail_name) {
                that.title = hosptail_name
            }
        },
        computed: {
            router_view_key() {
                return this.$route.path;
            },
        },
        watch: {
            router_view_key: {
                handler(newVal, oldVal) {
                    if (newVal == "/admin/index" || newVal == "/admin/indexs" || newVal == "/admin/indexUser" || newVal == "/admin/userData") {
                        this.is_fullscreen = true;
                    } else {
                        this.is_fullscreen = false;
                    }
                },
                immediate: true,
            },
        },
        methods: {
            goto_link(link) {
                if (link == "/admin/index" || link == "/admin/indexs" || link == "/admin/userData" || link == "/admin/indexUser") {
                    window.open(this.$router.resolve({path: link}).href, "_blank");
                } else {
                    this.$router.push(link);
                }
            },
            //编辑账号信息
            get_myinfo() {
                request({
                    url: "/hospital/Read/hospital/myinfo",
                    data: {
                        token: window.localStorage.getItem("token"),
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        console.log(ret.data)
                        this.myinfo = ret.data;
                        this.show_myinfo_form = true;
                    }
                });
            },
            submit_myinfo() {
                //提交账号信息
                request({
                    url: "/hospital/Write/hospital/pwd",
                    data: this.myinfo,
                }).then((ret) => {
                    if (ret.code == 1) {
                        // this.$notify({
                        // 	title: "操作成功",
                        // 	message: "数据已更新",
                        // 	type: "success",
                        // });
                        //TODO 更新头像、姓名、手机号
                        this.show_myinfo_form = false;
                        this.logout();
                    }
                });
            },
            cancel_myinfo() {
                //取消设置账号信息
                this.show_myinfo_form = false;
            },
            //退出
            logout() {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("name");
                window.localStorage.removeItem("head");
                this.$router.push("/");
            },
            fullScreen() {
                this.is_fullscreen = true;
                var el = document.documentElement;
                var rfs =
                    el.requestFullScreen ||
                    el.webkitRequestFullScreen ||
                    el.mozRequestFullScreen ||
                    el.msRequestFullScreen;

                //typeof rfs != "undefined" && rfs
                if (rfs) {
                    rfs.call(el);
                } else if (typeof window.ActiveXObject !== "undefined") {
                    //for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                    var wscript = new ActiveXObject("WScript.Shell");
                    if (wscript != null) {
                        wscript.SendKeys("{F11}");
                    }
                }
            },
        },
    };
</script>
